<template>
  <div>
    <loading :loading="loading"></loading>
    <div v-if="post" v-show="!loading">
      <v-layout pa-1>
        <a class="bread" href="/">{{ $t('home') }} </a>
        <div style="padding: 0 5px">{{ $t('greater-than') }}</div>
        <a class="bread" href="/activity">{{ $t('activities') }}</a>
      </v-layout>
      <v-carousel hide-delimiters height="260px">
        <v-carousel-item
          v-for="(image, i) in post.images"
          :key="i"
          :src="image.original"
          reverse-transition="fade"
          transition="fade"
          @click="dialog = true"
        ></v-carousel-item>
      </v-carousel>
      <v-container fluid grid-list-md pa-0>
        <v-layout row wrap>
          <v-flex xs12 md7>
            <v-dialog
              v-model="dialog"
              fullscreen
              hide-overlay
              transition="dialog-bottom-transition"
            >
              <v-card color="grey darken-4">
                <v-flex xs12>
                  <v-btn class="ma-4" icon dark @click.native="dialog = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-flex>
                <v-flex xs12 text-sm-center class="center">
                  <v-carousel class="carousel-img" hide-delimiters>
                    <v-carousel-item v-for="(image, i) in post.images" :key="i">
                      <img class="img" style="margin-top:120px;" :src="image.original" alt="" />
                    </v-carousel-item>
                  </v-carousel>
                </v-flex>
              </v-card>
            </v-dialog>
            <v-layout row wrap px-3>
              <v-flex xs12 my-2>
                <div class="activity-title">{{ post.name }}</div>
                <v-flex xs12 d-inline-flex>
                  <span class="rating">{{ $t('post-rating', [post.rating]) }}</span>
                  <v-rating
                    v-model="post.rating"
                    background-color="gray"
                    color="yellow accent-4"
                    dense
                    size="20"
                  ></v-rating>
                </v-flex>
              </v-flex>
            </v-layout>

            <v-layout row wrap px-3>
              <v-flex xs6>
                <div>
                  <div class="float-bottom">
                    <img src="@/assets/images/product_id.png" alt="" width="20" height="20" />
                    <span class="text">{{ $t('product-id-post-code', [post.code]) }}</span>
                  </div>
                </div>
              </v-flex>
              <v-flex xs6>
                <span class="float-right">
                  {{ $t('avg-post-price_type', [post.price_type]) }}
                </span>
                <br>
                <h2 class="green--text float-right">
                  {{
                    $root.$i18n.locale == "id"
                      ? "IDR " : "USD "
                  }}

                  {{
                    $root.$i18n.locale == "id"
                      ? post.price_idr : post.price_usd | fm
                  }}
                </h2>
              </v-flex>
            </v-layout>
            <v-flex xs12 px-3>
              <v-expansion-panels>
                <v-expansion-panel popout>
                  <v-expansion-panel-header>
                    <b>{{ $t('detail-information') }}</b>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <activity-detail :post="post" />
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <div class="highlight" v-if="post.highlight">
                <v-flex xs12 text-center>
                  <v-icon color="yellow darken-3">mdi-brightness-7</v-icon>
                  <span class="title">{{ $t('highlight') }}</span>
                </v-flex>
                <center>
                  <b>{{ post.short_des }}</b>
                </center>
                <br />
                <span v-html="post.highlight"></span>
              </div>
            </v-flex>

            <v-layout row wrap>
              <v-flex xs12 d-inline-flex>
                <v-tabs slider-color="deep-orange">
                  <v-tab ripple> DESCRIPTION </v-tab>
                  <!-- <v-tab ripple>
                  REVIEWS
                </v-tab> -->
                  <v-tab ripple> FAQ </v-tab>
                  <v-tab-item>
                    <v-card elevation="1" style="margin-bottom: 12px">
                      <v-card-text>
                        <div v-html="post.activity_des"></div>
                      </v-card-text>
                    </v-card>
                    <v-card elevation="1" mb-2 style="margin-bottom: 12px">
                      <v-card-text>
                        <div>
                          <b class="before_text">{{ $t('rate-includes') }}</b>
                          <div
                            class="check--green"
                            v-html="post.included"
                          ></div>
                          <br />
                        </div>
                      </v-card-text>
                    </v-card>
                    <div
                      style="margin-bottom: 12px; padding: 12px"
                      v-if="post.packages.length"
                    >
                      <b class="before_text">{{ $t('pricing-detail') }}</b>
                      <price-detail
                        :packages="post.packages"
                        @booking-now="bookingDialog = true"
                      ></price-detail>
                    </div>
                    <v-flex xs12 px-3>
                      <v-expansion-panels style="margin-bottom: 20px">
                        <v-expansion-panel
                          popout
                          v-if="post.confirmation_detail"
                        >
                          <v-expansion-panel-header
                            ><b class="before_text"
                              >{{ $t('confirmation-detail') }}</b
                            ></v-expansion-panel-header
                          >
                          <v-expansion-panel-content>
                            <v-flex v-html="post.confirmation_detail"></v-flex
                            ><br />
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel popout text v-if="post.whattobring">
                          <v-expansion-panel-header
                            ><b class="before_text"
                              >{{ $t('what-to-bring') }}</b
                            ></v-expansion-panel-header
                          >
                          <v-expansion-panel-content>
                            <v-flex v-html="post.whattobring"></v-flex><br />
                          </v-expansion-panel-content>
                        </v-expansion-panel>

                        <v-expansion-panel popout text v-if="post.pickup_time">
                          <v-expansion-panel-header
                            ><b class="before_text"
                              >{{ $t('pickup-time') }}</b
                            ></v-expansion-panel-header
                          >
                          <v-expansion-panel-content>
                            <v-flex v-html="post.pickup_time"></v-flex><br />
                          </v-expansion-panel-content>
                        </v-expansion-panel>

                        <v-expansion-panel popout text v-if="post.how_to_use">
                          <v-expansion-panel-header
                            ><b class="before_text"
                              >{{ $t('how-to-use') }}</b
                            ></v-expansion-panel-header
                          >
                          <v-expansion-panel-content>
                            <v-flex v-html="post.how_to_use"></v-flex><br />
                          </v-expansion-panel-content>
                        </v-expansion-panel>

                        <v-expansion-panel popout v-if="post.payment">
                          <v-expansion-panel-header
                            ><b class="before_text"
                              >{{ $t('payment') }} {{ $t('and-cancelation-policy') }}</b
                            ></v-expansion-panel-header
                          >
                          <v-expansion-panel-content>
                            <v-flex v-html="post.payment"></v-flex><br />
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                      <v-card elevation="0" style="margin-bottom: 8px">
                        <div class="additonal" v-if="post.note">
                          <v-flex xs12>
                            <v-icon color="orange">mdi-information</v-icon>
                            <b class="orange--text text-darken-1"
                              >{{ $t('additional-information') }}</b
                            >
                          </v-flex>
                          <v-flex v-html="post.note"></v-flex><br />
                        </div>
                      </v-card>
                    </v-flex>
                  </v-tab-item>
                  <!-- <v-tab-item>

                </v-tab-item> -->
                  <v-tab-item>
                    <v-card>
                      <v-card-text><faq /></v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-tabs>
              </v-flex>
            </v-layout>
          </v-flex>
          <!-- <v-flex xs12 md5>
          <div text-center>
            <span class="body-2">Meeting Point</span>
            <GmapMap
              :center="{lat:post.lat, lng:post.lon}"
              :zoom="14"
              class="carousel-img"
            >
              <GmapMarker
                :position="{lat:post.lat, lng:post.lon}"
                :clickable="true"
                :draggable="true"
                @click="center=m.position"
              />
            </GmapMap>
          </div>
          <br>
        </v-flex> -->
          <v-flex xs12>
            <v-card elevation="0">
              <v-card-text>
                <div>
                  <div v-if="comments.length">
                    <comment-header :avg="overview.avg"></comment-header>
                    <v-btn color="primary" block @click="isUser"
                      >{{ $t('write-review') }}</v-btn
                    ><br />
                    <span class="title">{{ $t('reviews') }}</span>
                    <comment-overview :overview="overview"></comment-overview>
                    <comment-item :comments="comments" />
                    <loading :loading="commentsloading"></loading>
                    <div
                      class="text-center"
                      v-if="!commentsloading && commentsUrl != null"
                    >
                      <v-btn
                        class="black--text"
                        outlined
                        color="deep-orange"
                        @click="getComments"
                        >{{ $t('read-more-reviews-remindcomments', [remindComments]) }}</v-btn
                      >
                    </div>
                  </div>
                  <div v-else>
                    <v-btn color="primary" block @click="isUser"
                      >{{ $t('write-review') }}</v-btn
                    ><br />
                    <v-flex xs12 text-center mt-2>
                      <img
                        src="@/assets/images/no_comment.jpg"
                        height="auto"
                        width="100%"
                        alt="$t('contact-us')"
                      /><br />
                    </v-flex>
                  </div>
                </div>
                <comment-dialog
                  :dialog="commentDialog"
                  :url="url"
                  @cancelDialog="commentDialog = false"
                  @commentSuccess="commentSuccess"
                ></comment-dialog>
                <login-dialog
                  :dialog="loginDialog"
                  @loginSuccess="loginSuccess"
                  @cancel="loginDialog = false"
                ></login-dialog>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs12 px-3>
            <v-flex mt-2>
              <span class="subheading black--text"
                >{{ $t('discover-more-bali-activities') }}</span
              >
            </v-flex>
            <v-flex v-for="(post, i) of others" :key="i">
              <activity-card-detail :post="post"></activity-card-detail>
            </v-flex>
          </v-flex>
        </v-layout>
      </v-container>
      <v-flex class="sticky" pa-0 xs12 background-color="white">
        <v-divider></v-divider>
        <v-container grid-list-xs pa-0>
          <v-layout row wrap pl-4 pr-2 align-center>
            <v-flex xs7>
              <small>{{ $t('starting-from') }}</small><br />
              <span class="green-text">
                {{ $root.$i18n.locale == "id" ? "IDR " : "USD " }}
                {{ $root.$i18n.locale == "id" ? post.price_idr : post.price_usd | fm }}
              </span>
              <small>{{ $t('price-post-price_type', [post.price_type]) }}</small>
            </v-flex>
            <v-flex xs4 class="text--right">
              <dialog-booking
                :dialog="bookingDialog"
                :bookingdata="bookingdata"
                :post="post"
                :type="type"
                @bookingData="bookingData"
                @bookingCancel="bookingDialog = false"
              ></dialog-booking>
              <dialog-confirm
                :dialog="confirmDialog"
                :bookingdata="bookingdata"
                :post="post"
                :type="type"
                @confirmSuccess="confirmSuccess"
                @confirmCancel="confirmDialog = false"
              ></dialog-confirm>
              <v-btn
                depressed
                rounded
                color="blue-grey darken-3 white--text text-capitalize"
                block
                @click="bookingDialog = true"
              >
                <span class="subheading">{{ $t('book-now') }}</span>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
      <v-dialog v-model="bookingSuccessDialog" persistent max-width="290">
        <v-card>
          <img class="success--image" src="@/assets/images/done.png" alt="" />
          <v-card-text>
            <div class="text--center">
              <span class="headline deep-orange--text text-darken-1"
                >{{ $t('thank-you') }}</span
              ><br />
              <span class="subheading"
                >{{ $t('please-wait-for-our-confirmation-and-check-your-email-inbox') }}.</span
              >
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="bookingSuccessDialog = false"
              >{{ $t('ok') }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <v-card elevation="0">
      <v-card-text>
        <v-layout>
          <v-flex xs9>
            <span class="body-2">{{ $t('did-you-need-help') }}</span>
          </v-flex>
          <v-flex xs3>
            <v-btn
              class="float-right"
              :to="{ name: 'helpandfaq' }"
              color="deep-orange white--text"
              small
              >{{ $t('faqs') }}</v-btn
            >
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
    <div class="box">
      <v-layout>
        <v-flex xs3>
          <img
            src="@/assets/images/cs.png"
            height="auto"
            width="100%"
            alt="$t('contact-us')"
          /><br />
        </v-flex>
        <v-flex xs9 pa-2>
          <span class="caption"
            >{{ $t('did-you-have-trouble-or-questions-feel-free-contact-us') }}</span
          ><br />
          <a class="email" href="mailto:support@balitripcenter.com">
            <span class="caption">support@balitripcenter.com</span>
          </a>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      loading: false,
      commentsloading: false,
      post: null,
      type: null,
      terjemah: [],
      others: [],
      errors: [],
      commentsUrl: null,
      comments: [],
      totalComments: null,
      commentDialog: false,
      loginDialog: false,
      overview: null,
      dialog: false,
      bookingSuccessDialog: false,
      bookingdata: null,
      bookingDialog: false,
      confirmDialog: false,
    };
  },
  mounted() {
    this.fetchData();
    // this.getComments()
  },
  watch: {
    $route: "fetchData",
  },
  methods: {
    fetchData() {
      this.loading = true;
      axios
        .get(
          `https://balitripcenter.com/api/activity/${this.$route.params.type}/${this.$route.params.slug}?lang=${this.$root.$i18n.locale}/`
        )
        .then((response) => {
          this.loading = false;
          this.type = response.data.items.type;
          this.post = response.data.items;
          this.terjemah = response.data.terjemah;
          this.others = response.data.others;
          //console.log(response.data.items);
        })
        .then((response) => {
          this.commentsUrl =
            `https://balitripcenter.com/api/activity/${this.$route.params.type}/${this.$route.params.slug}/comment`;
          this.getComments();
        })
        .catch((e) => {
          this.loading = false;
          this.errors.push(e);
        });
    },
    getComments() {
      this.commentsloading = true;
      axios
        .get(this.commentsUrl)
        .then((response) => {
          //console.log(response);
          this.commentsloading = false;
          this.comments = this.comments.concat(response.data.items);
          this.overview = response.data.overview;
          this.commentsUrl = response.data.items.next_page_url;
          this.totalComments = response.data.items.total;
          //console.log(this.comments.length);
        })
        .catch((e) => {
          this.commentsloading = false;
          this.errors.push(e);
        });
    },
    isUser() {
      if (this.$store.getters.isAuthenticated) {
        this.commentDialog = true;
      } else {
        this.loginDialog = true;
      }
    },
    loginSuccess() {
      this.loginDialog = false;
      this.commentDialog = true;
    },
    commentSuccess() {
      this.commentDialog = false;
      this.getComments();
    },
    bookingData(bookingdata) {
      this.bookingDialog = false;
      this.confirmDialog = true;
      this.bookingdata = bookingdata;
    },
    bookingCancel() {
      this.bookingDialog = false;
      this.bookingdata = null;
    },
    confirmCancel() {
      this.confirmDialog = false;
    },
    confirmSuccess(response) {
      this.confirmDialog = false;
      this.bookingSuccessDialog = true;
    },
  },
  computed: {
    url() {
      return (
        `https://balitripcenter.com/api/activity/${this.$route.params.type}/${this.$route.params.slug}/comment`
      );
    },
    remindComments() {
      return this.totalComments - this.comments.length;
    },
  },
};
</script>
<style scoped>
.green-text {
  color: #0fac39;
  font-weight: bold;
  font-size: 1.4rem;
  display: block;
}

.email,
.email:hover {
  text-decoration: none;
  color: black;
}
.v-breadcrumbs {
  padding: 8px !important;
  font-size: 12px;
}
.top {
  vertical-align: top;
}
.sticky {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: white;
  z-index: 1;
}
.carousel-img {
  width: 100%;
  height: 240px;
  align-content: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.img {
  width: 100%;
  height: 240px;
  object-fit: cover;
}

.success--image {
  height: 100px;
  width: 100%;
  object-fit: scale-down;
  justify-content: center;
  margin-top: 12px;
}

.text--center {
  text-align: center;
}

.center {
  height: 80vh;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (min-width: 768px) {
  .carousel-img {
    width: 100%;
    height: 440px;
    align-content: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .img {
    width: 100%;
    height: 440px;
    object-fit: cover;
  }
}

@media only screen and (min-width: 1024px) {
  .carousel-img {
    width: 100%;
    height: 340px;
    align-content: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .img {
    width: 100%;
    height: 340px;
    object-fit: cover;
  }
}

@media only screen and (min-width: 2560px) {
  .carousel-img {
    width: 100%;
    height: 960px;
    align-content: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .img {
    width: 100%;
    height: 960px;
    object-fit: cover;
  }
}

.v-center {
  vertical-align: middle;
}
.h-center {
  text-align: center;
}

.rating {
  background-color: #37464d;
  color: white;
  padding: 4px 8px;
  text-align: center;
  border-radius: 4px;
  width: auto;
  max-width: 40px;
}
.highlight {
  border-color: #ff6100;
  border-width: 1px;
  border-style: solid;
  padding: 4px;
  margin: 8px 0px;
  background-color: #fff;
  border-radius: 4px;
}
.additonal {
  border-color: #FF6100;
  border-width: 2px;
  border-style: solid;
  padding: 4px;
  background-color: #fffde7;
  border-radius: 4px;
}
.before_text {
  background-image: url("~@/assets/images/before_title.svg");
  background-repeat: no-repeat;
  padding-left: 10px; /* width of the image plus a little extra padding */
  display: inline-flex; /* may not need this, but I've found I do */
  font-size: 16px;
  font-weight: 600;
  background-position: 0% 50%;
  margin-bottom: 8px;
}
div.check--green {
  list-style-image: url("~@/assets/images/check.svg");
}

.bread {
  color: black;
  text-decoration: none;
}

.bread:hover {
  text-decoration: none;
  color: black;
}
.product_id {
  font-weight: bold;
  height: 25px;
  font-size: 12px;
  position: relative;
}

.vertical-center {
  margin: 0;
  width: 100%;
  position: absolute;
  bottom: 0;
}

.vertical-center img {
  width: 20px;
  height: 20px;
  margin: 0;
  position: absolute;
  top: 40%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.vertical-center .text {
  width: 100%;
  height: 20px;
  margin: 0;
  left: 25px;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.activity-title {
  margin-top: 8px;
  font-size: 20px;
  font-weight: bold;
  color: black;
}

.box {
  background-color: #e7f2f8;
  margin: 8px;
  padding: 8px;
  border-radius: 12px;
}

.box img {
  margin-top: 10px;
}
</style>