<template>
  <div>
    <agile :dots="true" :autoplay="true" :navButtons="false" :pauseOnHover="true">
      <div class="slide clip">
        <img src="@/assets/images/banner/b1.jpg" alt="" />
      </div>
      <div class="slide clip">
        <img src="@/assets/images/banner/b2.jpg" alt="" />
      </div>
      <div class="slide clip">
        <img src="@/assets/images/banner/b3.jpg" alt="" />
      </div>
      <div class="slide clip">
        <img src="@/assets/images/banner/b4.jpg" alt="" />
      </div>
      <div class="slide clip">
        <img src="@/assets/images/banner/b5.jpg" alt="" />
      </div>
    </agile>
    <v-container fluid grid-list-md>
      <v-layout row wrap>
        <v-flex xs12>
          <v-flex xs12 text-center pa-0 mb-4>
            <span class="text-h5">{{ $t("tagline") }}</span>
          </v-flex>
          <div class="pa-0">
            <main-card></main-card><br />
            <home-feature></home-feature>
            <divider></divider>
            <transport-feature></transport-feature>
            <divider></divider>
            <home-type></home-type>
            <divider></divider>
            <!-- <v-flex xs12 text-center mt-2>
            <v-btn color="deep-orange" rounded class="white--text" :to="{ name: 'activities'}">RECOMENDATIONS</v-btn><br><br>
            <span class="captions">We have selected recomendations activities for suggestions to help you choose one of best our activities</span>
          </v-flex> -->
            <home-specialoffer></home-specialoffer>
            <divider></divider>
            <home-event></home-event>
            <divider></divider>
            <home-populars></home-populars>
            <divider></divider>
            <home-recommendations></home-recommendations>
            <divider></divider>
            <home-water-sport></home-water-sport>
            <divider></divider>
            <home-tour></home-tour>
            <divider></divider>
            <loading :loading="loading"></loading>
            <home-login-card></home-login-card>
            <divider></divider>
            <honeymoon></honeymoon>
            <divider></divider>
            <rental></rental>
            <gallery-home></gallery-home>
            <divider></divider>
            <home-about></home-about>
            <divider></divider>
            <v-flex xs12 text-center mt-2>
              <v-btn
                text
                class="black--text text-h6"
                :to="{ name: 'destinations' }"
                >{{ $t('destinations')}}</v-btn
              ><br />
              <span style="font-size: 14px; color: gray"
                >{{ $t('t-plan-your-tour-to', [$t('plan-your-tour-to')]) }}</span
              >
            </v-flex>
            <home-destinations
              :destinations="items.destinations"
            ></home-destinations
            >
            <loading :loading="loading"></loading>
            <divider></divider>
            <v-flex xs12 text-center mt-2>
              <div style="color: #39cb8c">{{ $t('traveler-informasi') }}</div>
              <div style="font-size: 14px">
                {{ $t('seputar-pengetahuan') }} </div>
              <br />
            </v-flex>
            <home-tips :tips="items.tips"></home-tips><br />
            <loading :loading="loading"></loading>
            <divider></divider>
            <home-categories :items="items"></home-categories><br />


            <div class="box">
              <v-layout>
                <v-flex xs3>
                  <img
                    src="@/assets/images/cs.png"
                    height="auto"
                    width="100%"
                    alt="$t('contact-us')"
                  /><br />
                </v-flex>
                <v-flex xs9>
                  <span class="caption"
                    >{{ $t('t-did-you-have-trouble-or-questions-feel-free-contact-us', [$t('did-you-have-trouble-or-questions-feel-free-contact-us')]) }}</span
                  ><br />
                  <a class="email" href="mailto:support@balitripcenter.com">
                    <span class="caption">support@balitripcenter.com</span>
                  </a>
                </v-flex>
              </v-layout>
            </div>
          </div>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
/* eslint-disable */
import axios from "axios";
import Rental from "./Rental.vue";
import Honeymoon from "./Honeymoon.vue";
import HomeLoginCard from "./HomeLoginCard.vue";
import Divider from "../Divider.vue";
export default {
  components: { Rental, Honeymoon, HomeLoginCard, Divider },
  data() {
    return {
      loading: false,
      items: [],
      errors: [],
      images: [
        "@/assets/images/banner/1.jpg",
        "@/assets/images/banner/2.jpg",
        "@/assets/images/banner/3.jpg",
        "@/assets/images/banner/4.jpg",
        "@/assets/images/banner/5.jpg",
      ],
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading = true;
      axios
        .get(`https://balitripcenter.com/api/index`)
        .then((response) => {
          this.loading = false;
          this.items = response.data.items;
          //console.log(this.items);
        })
        .catch((e) => {
          this.loading = false;
          this.errors.push(e);
        });
    },
  },
};
</script>
<style scoped>
.email,
:hover {
  text-decoration: none;
  color: black;
}
.clip img {
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: top;
  border-bottom-right-radius: 50% 7%;
  border-bottom-left-radius: 50% 7%;
}

.box {
  background-color: #e7f2f8;
  margin: 8px;
  padding: 8px;
  border-radius: 12px;
}

.box img {
  margin-top: 10px;
}
</style>