<template>
  <div>
    <v-flex sm12 pa-2>
      <div class="search-container">
        <!-- <form action="#"> -->
        <input
          type="text"
          :placeholder="$t('find-activities-tours-4g-modem-and-private-transport')"
          v-model="queryInput"
        />
        <v-btn
          icon
          color="primary"
          @click="getInitialPosts"
          v-on:keyup.enter="getInitialPosts"
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
        <!-- </form> -->
      </div>
    </v-flex>
    <div v-if="posts && posts.length">
      <v-flex sm12 px-2>
        <h4>{{ $t('found-totalresults-activity', [totalResults]) }}</h4>
      </v-flex>

      <v-container pa-2 fluid grid-list-md>
        <v-layout row wrap>
          <v-flex v-for="(post, i) of posts" :key="i" xs12 sm6 xl4>
            <activity-card :post="post"></activity-card>
          </v-flex>
        </v-layout>
      </v-container>
      <div class="text-center" v-if="!loading && currentPage != null">
        <v-btn rounded class="white--text" color="green" @click="getPosts"
          >{{ $t('load-more') }}</v-btn
        >
      </div>
    </div>
    <v-flex xs12 v-else v-show="!loading" ma-2 text-center>
      <span>{{ $t('search-something') }}</span>
    </v-flex>
    <loading :loading="loading"></loading>

    <v-flex sm12 pa-2>
      <home-populars></home-populars><br />
      <home-recommendations></home-recommendations><br />
    </v-flex>
  </div>
</template>
<script>
/* eslint-disable */
import axios from "axios";
const api = "https://balitripcenter.com/api/search?query=";

export default {
  props: ["initialQuery"],
  data() {
    return {
      queryInput: null,
      loading: false,
      posts: [],
      errors: [],
      currentPage: api,
      items: [],
      totalResults: null,
      category: null,
      category_name: null,
      category_des: null,
      active: null,
    };
  },
  beforeMount() {
    // this.getCategories()
  },
  mounted() {
    //console.log("query " + this.initialQuery);
    if (this.initialQuery) {
      this.queryInput = this.initialQuery;
      this.getInitialPosts();
    }
    //console.log("tag "+this.query);
    // this.getPosts()
    // if (!this.type) {
    //   this.getPosts()
    // } else {
    //   this.selected(this.type)
    // }
    // this.active = this.type
  },
  methods: {
    // scroll () {
    //   let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight
    //   if (bottomOfWindow && !this.loading && this.currentPage) {
    //     this.loadMore()
    //   }
    // },
    // selected (id) {
    //   this.posts = []
    //   if (id == null) {
    //     this.currentPage = 'https://balitripcenter.com/api/type'
    //   } else {
    //     this.currentPage = 'https://balitripcenter.com/api/type/' + id
    //   }
    //   this.category = null
    //   this.getPosts()
    // },
    // getCategories () {
    //   axios.get('https://balitripcenter.com/api/types')
    //   .then(response => {
    //     this.items = this.items.concat(response.data.items)
    //     this.active = this.type
    //   })
    //   .catch(e => {

    //   })
    // },
    getInitialPosts() {
      this.loading = true;
      this.currentPage = api + this.queryInput;
      //console.log("Initial page " + this.currentPage);
      this.posts = [];

      axios
        .get(this.currentPage)
        .then((response) => {
          this.loading = false;
          this.posts = this.posts.concat(response.data.items.data);
          this.category = response.data.category;
          this.currentPage = response.data.items.next_page_url;
          this.totalResults = response.data.items.total;
        })
        .catch((e) => {
          this.loading = false;
          this.errors.push(e);
        });
    },
    getPosts() {
      this.loading = true;
      //console.log("Current page " + this.currentPage);
      axios
        .get(this.currentPage)
        .then((response) => {
          this.loading = false;
          this.posts = this.posts.concat(response.data.items.data);
          this.category = response.data.category;
          this.currentPage = response.data.items.next_page_url;
        })
        .catch((e) => {
          this.loading = false;
          this.errors.push(e);
        });
    },
    // setActiveTab (items) {
    //   return items.findIndex(item => {
    //     return item.id == this.type
    //   })
    // }
  },
};
</script>
<style scoped>
.search-container {
  position: relative;
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  background-color: #fff;
  border-color: #ccc;
}

.search-container input[type="text"] {
  font-size: 17px;
  padding: 8px;
  width: 100%;
}
.search-container button {
  position: absolute;
  right: 0;
  padding: 8px;
  font-size: 17px;
  border: none;
  height: 100%;
  color: #222;
  background-color: #eee;
  cursor: pointer;
  border-radius: 0 4px 4px 0;
}

.search-container button:hover {
  background: #ccc;
}

.block {
  background: url("~@/assets/images/background_down_xs.png") no-repeat;
  background-position: center;
  background-size: 100% auto;
  margin: 4px;
  font-size: 20px;
  width: auto;
  object-position: center;
  color: white;
  display: block;
}

.rating {
  background-color: #37464d;
  color: white;
  padding: 4px;
  text-align: center;
  border-radius: 4px;
  width: auto;
  max-width: 40px;
}
</style>