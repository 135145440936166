<template>
  <div>
    <v-flex text pa-0>
      <img
        src="@/assets/images/auth-hero.jpg"
        height="auto"
        width="100%"
        alt="$t('register')"
      /><br />
    </v-flex>
    <v-container fluid grid-list-md>
      <v-layout row wrap pa-2>
        <v-flex xs12 text-center>
          <span class="title">{{ $t('register-with') }}</span>
        </v-flex>
        <social-button @success="success"></social-button>
        <v-flex xs12 text-center mt-2>
          <span class="subheading">{{ $t('or') }}</span>
        </v-flex>
        <v-flex xs12 sm8 offset-sm2 md4 offset-md4>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field
              v-model="username"
              :rules="usernameRules"
              :label="$t('username')"
              required
              :error-messages="usernameError"
            ></v-text-field>
            <v-text-field
              v-model="email"
              :rules="emailRules"
              label="E-mail"
              required
              :error-messages="emailError"
            ></v-text-field>
            <v-text-field
              v-model="password"
              type="password"
              :rules="passwordRules"
              :label="$t('password')"
              required
              :counter="8"
              :error-messages="passwordError"
            ></v-text-field>
            <v-text-field
              v-model="reenteredPassword"
              type="password"
              :rules="reenteredPasswordRules"
              :label="$t('confirm-password')"
              required
              :error-messages="reenteredPasswordError"
              :counter="8"
            ></v-text-field>
            <v-btn
              color="green"
              class="white--text"
              block
              @click="submit"
              :disabled="!valid"
              :loading="loading"
            >
              {{ $t('register') }} </v-btn>
          </v-form>
        </v-flex>
        <v-flex mt-4 xs12 sm8 offset-sm2 md4 offset-md4>
          {{ $t('have-an-account') }} <v-btn outlined color="blue" to="login">{{ $t('login') }}</v-btn>
        </v-flex>
      </v-layout>
      <v-snackbar v-model="snackbar" :color="'red'" :timeout="-1">
        {{ text }}
        <template v-slot:action="{ attrs }">
          <v-btn text @click="snackbar = false" v-bind="attrs"> {{ $t('close') }} </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
import axios from "axios";
export default {
    data() {
    return {
    valid: false,
    username: "",
    usernameRules: [(v) => !!v || this.$t('username-is-required')],
    usernameError: [],
    password: "",
    passwordRules: [
      (v) => !!v || this.$t('password-is-required'),
      (v) => v.length >= 8 || this.$t('name-must-be-more-than-8-characters'),
    ],
    passwordError: [],
    reenteredPassword: "",
    reenteredPasswordRules: [
      (v) => !!v || this.$t('password-is-required'),
      (v) => v.length >= 8 || this.$t('name-must-be-more-than-8-characters'),
    ],
    reenteredPasswordError: [],
    email: "",
    emailRules: [
      (v) => !!v || this.$t('e-mail-is-required'),
      (v) => /.+@.+/.test(v) || this.$t('e-mail-must-be-valid'),
    ],
    emailError: [],
    loading: false,
    snackbar: false,
    text: null,
    };
  },
  methods: {
    submit() {
      if (this.matchPassword && this.$refs.form.validate()) {
        this.reenteredPasswordError = [];
        this.usernameError = [];
        this.emailError = [];
        this.loading = true;
        axios
          .get(`https://balitripcenter.com/sanctum/csrf-cookie`)
          .then((res) => {
            axios
              .post(`https://balitripcenter.com/api/register`, {
                name: this.username,
                email: this.email,
                password: this.password,
              })
              .then((response) => {
                this.loading = false;
                this.success(response);
              })
              .catch((e) => {
                this.loading = false;
                //console.log(e.response.data);
                if (e.response.data.message.name != null) {
                  this.usernameError.push(e.response.data.message.name[0]);
                }
                if (e.response.data.message.email != null) {
                  this.emailError.push(e.response.data.message.email[0]);
                }
                this.$refs.form.resetValidation();
              });
          });
      } else {
        this.reenteredPasswordError.push(this.$t('password-doesnt-match'));
      }
    },
    success(response) {
      this.$store.dispatch("setToken", {
        token: response.data.token,
      });
      if (this.$route.params.nextUrl != null) {
        this.$router.push(this.$route.params.nextUrl);
      } else {
        this.$router.push("/");
      }
    },
  },
  computed: {
    matchPassword: function () {
      return this.password === this.reenteredPassword;
    },
  },
};
</script>
