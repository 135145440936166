<template>
  <div>
    <div v-if="posts && posts.length" v-show="!loading">
      <v-container pa-2 fluid grid-list-md>
        <v-layout row wrap>
          <v-flex v-for="(post, i) of posts" :key="i" xs12 sm6 xl4>
            <home-event-card :post="post"></home-event-card>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
    <loading :loading="loading"></loading>
  </div>
</template>
<script>
import axios from "axios";
const api = "https://balitripcenter.com/api/event";

export default {
  data() {
    return {
      loading: false,
      posts: [],
      errors: [],
    };
  },
  created() {
    this.getInitialPosts();
  },
  methods: {
    getInitialPosts() {
      this.loading = true;
      axios
        .get(api)
        .then((response) => {
          this.loading = false;
          this.posts = response.data.items;
        })
        .catch((e) => {
          this.loading = false;
          this.errors.push(e);
        });
    },
  },
};
</script>
<style scoped>
</style>